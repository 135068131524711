<template>
  <div class="medal-wall">
    <img class="static-bg-1" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/bg-1.webp" alt="">
    <Header></Header>
    <MedalContent></MedalContent>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MedalContent from './MedalContent.vue'

export default {
  name: 'MedalWall',
  components: {
    Header,
    Footer,
    MedalContent,
  },
}
</script>
<style lang="scss" scoped>
.medal-wall {
  color: #fff;
  position: relative;
  
  .static-bg-1 {
    position: absolute;
    top: -99px;
    left: 0;
    z-index: 0;
  }
}
</style>