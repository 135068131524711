<template>
  <div class="medal-wall-content md:px-12 md:flex-col-reverse">
    <div class="content-left md:block md:text-center">
      <span class="tit md:text-32 md:leading-40 md:mb-16 md:mr-16">BP-FLAC</span>
      <span class="tit-second md:text-32 md:leading-40 md:mb-16">Medal Wall</span>
      <p class="md:text-12 md:mb-52">Claim SBT Medals: Illuminate your achievements on the journey with BP-FLAC</p>
      <div class="btn md:mx-auto">
        <span v-if="!isConnect" @click="$router.push({ name: 'Setting' })">Connect Wallet</span>
        <span v-if="isConnect && !isSocial" @click="$router.push({ name: 'Setting' })">Connect Social Media</span>
        <span v-if="isConnect && isSocial" @click="$router.push({ name: 'AllMedalWall' })">Enter Medal Wall</span>
      </div>
    </div>
    <div class="content-right md:pt-32 md:pb-48 md:flex-col md:gap-16">
      <div class="mask-up md:hidden"></div>
      <div class="mask-down md:hidden"></div>
      <div class="animation-up md:hidden">
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/OutstandingCaptain.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/OutstandingTeam.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/HonorableCaptain.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/HonorableTeam.png"/></div>

        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/OutstandingCaptain.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/OutstandingTeam.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/HonorableCaptain.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/HonorableTeam.png"/></div>
      </div>

      <div class="animation-left-md md:flex">
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/OutstandingCaptain.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/OutstandingTeam.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/HonorableCaptain.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/HonorableTeam.png"/></div>

        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/OutstandingCaptain.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/OutstandingTeam.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/HonorableCaptain.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/HonorableTeam.png"/></div>
      </div>

      <div class="animation-down md:hidden">
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv1.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv2.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv3.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/StarStuddedCaptain.png"/></div>

        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv1.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv2.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv3.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/StarStuddedCaptain.png"/></div>
      </div>

      <div class="animation-right-md md:flex">
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv1.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv2.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv3.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/StarStuddedCaptain.png"/></div>

        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv1.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv2.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/EarlySettlersLv3.png"/></div>
        <div class="medal-item md:mr-16"><img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/MedalPNG/StarStuddedCaptain.png"/></div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAddress } from '@/utils/auth.js'
import { userInfo } from '../../utils/twitter.api.js';
export default {
  data() {
    return {
      isConnect: false,
      isSocial: false,
      address: '',
    }
  },
  created() {
    const account = getAddress()
    this.address = account
    if (account) {
      this.isConnect = true
    } else {
      this.isConnect = false
    }
    this.getUserInfo()
  },
  methods: {
    // 用户信息
    async getUserInfo() {
      try {
        const res = await userInfo({
          addr: this.address,
        });
        if (res.code === 200) {
          let data = res.data
          // if (!data.dc_name || !data.tw_name) {
          //   this.isSocial = false
          // } else {
          //   this.isSocial = true
          // }
          this.isSocial = data.dc_name && data.tw_name
          console.log(this.isSocial)
        }
      } catch (error) {
        console.log(error)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/utils/variables.scss"; // 导入之前定义的颜色变量文件

.medal-wall-content {
  background-color: $bacColor;
  color: $fontcolor;

  position: relative;
  z-index: 1;
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 120px;

  .content-left {
    display: flex;
    flex-direction: column;

    .tit {
      display: inline-block;
      font-size: 72px;
      font-weight: 800;
      line-height: 92px;
      background-image: linear-gradient(to right, #33C4F2, #1F35FD);
      background-clip: text;
   
      margin-bottom: 16px;
    }

    .tit-second {
      display: inline-block;
      font-size: 86px;
      font-style: normal;
      font-weight: 700;
      line-height: 92px;
      letter-spacing: 0.86px;
      margin-bottom: 40px;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      margin: 0;
      margin-bottom: 62px;
    }

    .btn {
      width: 240px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
      cursor: pointer;

      span {
        display: inline-block;
        text-align: center;
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: 600;
        line-height: 48px;
      }
    }
  }
  .content-right {
    display: flex;
    max-height: calc(100vh - 80px);
    overflow: hidden;
    align-items: flex-start;
    gap: 32px;
    justify-content: space-between;
    position: relative;
    z-index: 0;
    .animation-up, .animation-down {
      display: flex;
      flex-direction: column;
      /* gap: 40px; */
      .medal-item {
        width: 320px;
        height: 320px;
        border-radius: 24px;
        overflow: hidden;
        border: 1px solid rgba(255, 255, 255, 0.20);
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .animation-left-md, .animation-right-md {
      display: none;
      flex-direction: row;
      /* gap: 40px; */
      .medal-item {
        width: 120px;
        height: 120px;
        border-radius: 24px;
        overflow: hidden;
        border: 1px solid rgba(255, 255, 255, 0.20);
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      
    }
    .animation-up {
      transform: translate3d(0, 0, 0);
      animation: k-up 40s linear infinite;
      .medal-item {
        margin-bottom: 40px;
      }
    }
    .animation-down {
      transform: translate3d(0, -50%, 0);
      animation: k-down 40s linear infinite;
      .medal-item {
        margin-top: 40px;
      }
    }

    .animation-left-md {
      transform: translate3d(27%, 0, 0);
      animation: k-left 40s linear infinite;
    }
    .animation-right-md {
      transform: translate3d(-25%, 0, 0);
      animation: k-right 40s linear infinite;
    }
    .mask-up, .mask-down {
      width: 100%;
      height: 180px;
      position: absolute;
      left: 0;
      z-index: 1;
    }
    .mask-up {
      top: 0;
      background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.94) 50.52%, rgba(0, 0, 0, 0.00) 100%);
    }
    .mask-down {
      bottom: 0;
      background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.94) 50.52%, rgba(0, 0, 0, 0.00) 100%);
    }
  }
}
@keyframes k-up {
  0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	100% {
		-webkit-transform: translate3d(0, -50%, 0);
		transform: translate3d(0, -50%, 0);
	}
}
@keyframes k-down {
  0% {
		-webkit-transform: translate3d(0, -50%, 0);
		transform: translate3d(0, -50%, 0);
	}

	100% {
		-webkit-transform: translate3d(0, 0%, 0);
		transform: translate3d(0, 0%, 0);
	}
}

@keyframes k-left {
  0% {
		-webkit-transform: translate3d(27%, 0, 0);
		transform: translate3d(27%, 0, 0);
	}

	100% {
		-webkit-transform: translate3d(-23%, 0%, 0);
		transform: translate3d(-23%, 0%, 0);
	}
}
@keyframes k-right {
  0% {
		-webkit-transform: translate3d(-25%, 0, 0);
		transform: translate3d(-25%, 0, 0);
	}

	100% {
		-webkit-transform: translate3d(25%, 0%, 0);
		transform: translate3d(25%, 0%, 0);
	}
}
</style>